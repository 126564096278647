import React from 'react'
import Job from './Job'



export default function Jobopenings() {

  
  return (
    <>
    <div className='container-main'>
      <Job />
    </div>
    </>
      
  )
}



